import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { sendVetTrace } from '@/src/sources/common/lightUbt';
import Converter from '../../../../sources/list/converter';
import CONSTS from '../../../../constants/list/constConfig';
import { UbtBoundary, ListUbtGenerator, LIST_UBT_GROUP_TYPES, LIST_UBT_KEY_TYPES } from '../../../../ubt.v2/list';
import { onResearchFlightsWithFullUrl } from '../../../../actions/list/getGlobalSearchCriteria';
import { scrollToFirstFlight } from '../../../../sources/list/scrollToFirstFlight';
import { getIfScopeDomestic } from '../../../../sources/list/scope';

const ActiveIndicator = ({ width = 114, left }) => {
	return (
		<React.Fragment>
			<div
				style={{
					position: 'absolute',
					top: 0,
					left: 0,
					bottom: 0,
					width,
					backgroundColor: '#0086F6',
					borderRadius: 8,
					transition: 'transform 0.4s ease-in-out',
					transform: `translateX(${left}px)`,
					msTransform: `translateX(${left}px)`,
				}}
			/>
			<div
				style={{
					zIndex: 1,
					position: 'absolute',
					top: 0,
					left: 0,
					bottom: 0,
					width: width + 5,
					borderLeft: '4px solid #fff',
					borderRight: '4px solid #fff',
					transition: 'transform 0.4s ease-in-out',
					boxSizing: 'border-box',
					transform: `translateX(${left - 2}px)`,
					msTransform: `translateX(${left}px)`,
				}}
			/>
		</React.Fragment>
	);
};

// 一屏显示7天
const DAY_COUNT = 7;
const SPLIT_CHAR = '-';

const getUrl = (depdate, rtdate, flightWayRound, toRememberActiveKeys) => {
	let url = '';
	const { href } = window.location;
	if (href && href.includes('depdate=')) {
		if (flightWayRound) {
			url = window.location.href.replace(/depdate=([0-9]|-)+_([0-9]|-)+/, `depdate=${depdate}_${rtdate}`);
		} else {
			url = window.location.href.replace(/depdate=([0-9]|-)+/, `depdate=${depdate}`).replace('&nearByDate', '');
		}
	} else {
		if (flightWayRound) {
			url = window.location.href + `&depdate=${depdate}_${rtdate}`;
		} else {
			url = window.location.href + `&depdate=${depdate}`;
		}
	}

	return Converter.batchUpdateUrlQueryString(url, toRememberActiveKeys);
};

const RoundDateArea = ({ item }) => {
	return (
		<div>
			<div className="date">
				{`${item.get('date').split('-')[1]}${SPLIT_CHAR}${item.get('date').split('-')[2]}`}
				<span className={item.get('holiday') ? 'holiday' : 'week'}>{item.get('week')}</span>
				<span className="hint">去</span>
			</div>
			<div className="date">
				{`${item.get('date2').split('-')[1]}${SPLIT_CHAR}${item.get('date2').split('-')[2]}`}
				<span className={item.get('holiday2') ? 'holiday' : 'week'}>{item.get('week2')}</span>
				<span className="hint">返</span>
			</div>
		</div>
	);
};

const OneWayDateArea = ({ item }) => {
	return (
		<div className="date">
			{`${item.get('date').split('-')[1]}${SPLIT_CHAR}${item.get('date').split('-')[2]}`}
			<span className={item.get('holiday') ? 'holiday' : 'week'}>{item.get('week')}</span>
		</div>
	);
};

const TabItem = ({ item, index, lowPrice, flightWayRound, toRememberActiveKeys, firstItemRef }) => {
	let lowPriceIsInfinity = item.get('price') >= Infinity;
	return (
		<UbtBoundary
			tracker={ListUbtGenerator(
				LIST_UBT_GROUP_TYPES.LOW_PRICE_CALENDAR_CLICK,
				LIST_UBT_KEY_TYPES.LOW_PRICE_CALENDAR_CLICK,
				lowPrice ? 'c_select_lp_price_top' : 'c_select_price_top'
			)}
		>
			<li
				ref={index === 0 ? firstItemRef : null}
				u_key="low_price_tab"
				u_remark={`低价日历日期tab[active:${item.get('active', 'false')},price:${item.get(
					'price',
					'-'
				)},date:${item.get('date')},date2:${item.get('date2', '-')}]`}
				className={'tab' + (item.get('active') ? ' active' : '')}
				onClick={() => {
					onResearchFlightsWithFullUrl(
						getUrl(
							item.get('date'),
							item.get('date2') ? item.get('date2') : 0,
							flightWayRound,
							toRememberActiveKeys
						),
						{ keepFilterActiveKey: getIfScopeDomestic(), needResetCalendar: false }
					);
				}}
			>
				{flightWayRound ? <RoundDateArea item={item} /> : <OneWayDateArea item={item} />}
				{item.get('noPrice') || lowPriceIsInfinity ? (
					<div className="link" style={{ color: item.get('active') ? '#FFF' : '' }}>
						查看
					</div>
				) : item.get('priceGone') ? (
					''
				) : lowPrice ? (
					<div className="price lowprice">
						<dfn>&yen;</dfn>
						{item.get('price')}
						<i className="tag tag-lowprice">低</i>
					</div>
				) : (
					<div className="price">
						<dfn>&yen;</dfn>
						{item.get('price')}
					</div>
				)}
			</li>
		</UbtBoundary>
	);
};

class NormalCalendarLowPrice extends React.Component {
	constructor(props) {
		super(props);

		this.firstItemRef = React.createRef();
		this.itemsRootRef = React.createRef();
		this.lastUpdateItemWidthAt = new Date(2018, 0, 1);

		const { width } = this.getInitIndicatorArea();

		this.state = {
			itemWidth: width,
		};
	}

	updateDimensions = () => {
		const { width } = this.getInitIndicatorArea();

		if (this.state.itemWidth !== width) {
			this.setState({ itemWidth: width });
		}
	};

	getIfShowIndicator = () => this.props.calendarLPData && this.props.calendarLPData.size >= DAY_COUNT;

	getInitIndicatorArea = () => (this.getIfNarrowModeSwitch() ? { width: 114 } : { width: 130 });

	componentDidUpdate(prevProps, _prevState) {
		if (prevProps.searchCriteria.get('flightWay') !== this.props.searchCriteria.get('flightWay')) {
			setTimeout(() => {
				this.updateDimensions();
			}, 50);
		}

		const prevActiveItem = prevProps.calendarLPData.find((item) => item.get('active'));
		const currentActiveItem = this.props.calendarLPData.find((item) => item.get('active'));
		if (prevActiveItem && currentActiveItem && prevActiveItem.get('date') !== currentActiveItem.get('date')) {
			const { fixSortBar, fixLowPriceCalendar } = this.props;
			scrollToFirstFlight(fixSortBar, fixLowPriceCalendar);
		}

		if (prevProps.currentStart !== this.props.currentStart) {
			const offsetCount =
				Math.ceil((this.props.currentStart - this.getActiveItemIndex()) / DAY_COUNT) * DAY_COUNT;

			if (this.itemsRootRef.current) {
				this.itemsRootRef.current.style.marginLeft = `${(offsetCount - 7) * this.state.itemWidth}px`;
				setTimeout(
					() =>{
						if(this.itemsRootRef.current) {
							this.itemsRootRef.current.style.transform = `translateX(${
								-1 * (offsetCount * this.state.itemWidth)
							}px)`
						}
					},
					0
				);
			}
		}
	}

	getIfItemIsLowPrice = (item) => this.props.normalLowPrice.indexOf(item.get('date')) >= 0;

	getTabTitle = () => {
		//单程往返样式不同
		return this.props.calendarLPData.map((item, index) => (
			<TabItem
				key={item.get('date')}
				index={index}
				item={item}
				lowPrice={this.getIfItemIsLowPrice(item)}
				flightWayRound={this.getIfFlightWayRound()}
				toRememberActiveKeys={this.props.toRememberActiveKeys}
				firstItemRef={this.firstItemRef}
			/>
		));
	};

	getCurrentStartIndex = () => this.props.currentStart;

	getActiveItemIndex = () => this.props.calendarLPData.getIn([0, 'activeItemIndex']); // activeItemIndex存在第一个元素里面..

	getActiveItemIsPrev = () => this.getActiveItemIndex() < this.props.currentStart;

	getActiveTabTitleIndicator = () => {
		if (this.getIfShowIndicator()) {
			const activeItemIndex = this.getActiveItemIndex(),
				currentStartIndex = this.getCurrentStartIndex(),
				activeItemIsPrev = this.getActiveItemIsPrev(),
				activeItemVisible =
					activeItemIndex >= currentStartIndex && activeItemIndex < currentStartIndex + DAY_COUNT,
				left = activeItemVisible
					? activeItemIndex === currentStartIndex
						? 0
						: (activeItemIndex - currentStartIndex) * this.state.itemWidth -
						  (this.getIfNarrowModeSwitch() ? 0 : 1)
					: activeItemIsPrev
					? 0
					: document.body.clientWidth;

			return <ActiveIndicator left={left} width={activeItemVisible ? this.state.itemWidth : 0} />;
		} else {
			return null;
		}
	};

	getIfFlightWayRound = () => this.props.flightWay === 'D';

	getIfNarrowModeSwitch = () =>
		this.getIfFlightWayRound() && this.props.globalSwitch.get('roundTabModeSwitch') && !getIfScopeDomestic();

	render() {
		//通过当前起始位置计算初始dom位置（22是因为向左按钮22像素，142为tab宽度）
		const { fixLowPriceCalendar, toggleBuildUp, isBuildUp } = this.props,
			showFreeStyleOrBuildUp =
				this.getIfFlightWayRound() && this.getIfNarrowModeSwitch() && !getIfScopeDomestic();

		let preBoxClass = this.props.currentStart === 0 ? 'pre-btn disabled' : 'pre-btn',
			diffDaysSearched = moment(this.props.searchCriteria.getIn(['flightSegments', 1, 'departureDate'])).diff(
				this.props.searchCriteria.getIn(['flightSegments', 0, 'departureDate']),
				'days'
			),
			endDay =
				this.props.searchCriteria.get('flightWay') === 'S'
					? moment(moment().format('YYYY-MM-DD')).add(CONSTS.RECOMMEND.CALENDAR_LP_SINGLE_DAYSLIMIT, 'days')
					: moment(moment().format('YYYY-MM-DD'))
							.add(CONSTS.RECOMMEND.CALENDAR_LP_ROUNG_DAYSLIMIT, 'days')
							.subtract(diffDaysSearched, 'days'),
			totalDays = endDay.diff(moment(moment().format('YYYY-MM-DD')), 'days'),
			nextBoxClass = this.props.currentStart + DAY_COUNT > totalDays ? 'next-btn disabled' : 'next-btn',
			leftCount = this.props.currentStart <= DAY_COUNT ? this.props.currentStart : DAY_COUNT,
			rightCount =
				totalDays - this.props.currentStart < DAY_COUNT * 2
					? totalDays - this.props.currentStart - DAY_COUNT - 1
					: DAY_COUNT,
			toggleBuildUpDisabled = getIfScopeDomestic(),
			onToggleBuildUp = (buildUp) => {
				if (toggleBuildUpDisabled) return;
				toggleBuildUp(buildUp);
			};

		return (
			<div className={classnames('calendar-lowprice-root', 'fix-sort-bar')}>
				<div
					className={classnames('calendar-lowprice', {
						fixed: fixLowPriceCalendar,
						'toggle-build-up': showFreeStyleOrBuildUp,
					})}
				>
					<div className="calendarlp-tab">
						<UbtBoundary
							tracker={ListUbtGenerator(
								LIST_UBT_GROUP_TYPES.LOW_PRICE_CALENDAR_CLICK,
								LIST_UBT_KEY_TYPES.LOW_PRICE_CALENDAR_CLICK,
								'c_enter_lowprice_top'
							)}
						>
							<span
								u_key="toggle_low_price"
								u_remark={`切换是否显示低价日历`}
								className="open-btn"
								onClick={()=> {
									sendVetTrace({
                                        key: 'online_flight_more_date_click_test'
                                    });
									this.props.switchShow.bind(this, true)();
								}}
							>
								<i className="ico-calendar"></i>
								<span>更多日期</span>
							</span>
						</UbtBoundary>
						{showFreeStyleOrBuildUp ? (
							<span className="toggle-build-up">
								<span className="indicator" style={{ left: isBuildUp ? 70 : 4 }}></span>
								<UbtBoundary
									tracker={ListUbtGenerator(
										LIST_UBT_GROUP_TYPES.SPLIT_ROUND_CLICK_FREE_STYLE,
										LIST_UBT_KEY_TYPES.SPLIT_ROUND_CLICK_FREE_STYLE
									)}
								>
									<span
										u_key="split_round_free_style"
										u_remark={`往返分屏切换到自由搭配`}
										className={classnames({ active: !isBuildUp })}
										onClick={(_) => onToggleBuildUp(false)}
									>
										自由搭配
									</span>
								</UbtBoundary>
								<UbtBoundary
									tracker={ListUbtGenerator(
										LIST_UBT_GROUP_TYPES.SPLIT_ROUND_CLICK_BUILD_UP,
										LIST_UBT_KEY_TYPES.SPLIT_ROUND_CLICK_BUILD_UP
									)}
								>
									<span
										u_key="split_round_build_up"
										u_remark={`往返分屏切换到往返组合`}
										className={classnames({ active: isBuildUp, disabled: toggleBuildUpDisabled })}
										onClick={(_) => onToggleBuildUp(true)}
									>
										往返组合
									</span>
								</UbtBoundary>
							</span>
						) : null}
						<div className={'tabs'}>
							{this.getActiveTabTitleIndicator()}
							<div className={classnames('tabs-content', { 'narrow-mode': showFreeStyleOrBuildUp })}>
								<ul ref={this.itemsRootRef}>
									{this.getTabTitle()}
									{this.getTabTitle()}
									{this.getTabTitle()}
								</ul>
							</div>
							<UbtBoundary
								tracker={ListUbtGenerator(
									LIST_UBT_GROUP_TYPES.LOW_PRICE_CALENDAR_CLICK,
									LIST_UBT_KEY_TYPES.LOW_PRICE_CALENDAR_CLICK,
									'c_lastweek_top'
								)}
							>
								<div
									u_key="prev_low_price"
									u_remark="低价日历向左切换"
									className={preBoxClass}
									onClick={
										this.props.currentStart !== 0
											? this.props.changeCurrentStart.bind(
													this,
													this.props.currentStart - leftCount
											  )
											: null
									}
								>
									<i className="arrow-left-bold"></i>
								</div>
							</UbtBoundary>
							<UbtBoundary
								tracker={ListUbtGenerator(
									LIST_UBT_GROUP_TYPES.LOW_PRICE_CALENDAR_CLICK,
									LIST_UBT_KEY_TYPES.LOW_PRICE_CALENDAR_CLICK,
									'c_nextweek_top'
								)}
							>
								<div
									u_key="next_low_price"
									u_remark="低价日历向右切换"
									className={nextBoxClass}
									onClick={
										this.props.currentStart + DAY_COUNT <= totalDays
											? this.props.changeCurrentStart.bind(
													this,
													this.props.currentStart + rightCount
											  )
											: null
									}
								>
									<i className="arrow-right-bold"></i>
								</div>
							</UbtBoundary>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default NormalCalendarLowPrice;
