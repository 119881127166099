import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
	genSwitchCalendarShow,
	genChangeCurrentStart,
	genChangeCurrentMonth,
	fetchLowerPriceAllSwitch,
} from '../../../../actions/list/calendarLowprice';
import { getCalendarLPData, getNormalLowPrice } from './calendarLPSelector.js';
import {
	getToRememberActiveKeysSelector,
	searchFlightsIsFinishedSelector,
	getRouterKeySelector,
	getFixLowPriceCalendarSelector,
	isBuildUpSelector,
	getRawSplitRoundFlightsSwitchSelector,
	globalSwitchSelector,
	prevCondFlightWaySelector,
	getRoundTabModeSwitchSelector,
	getFixSortBarSelector,
} from '../../result/flight/baseSelector';
import { UbtBoundary, ListUbtGenerator, LIST_UBT_GROUP_TYPES, LIST_UBT_KEY_TYPES } from '../../../../ubt.v2/list';
import { windowScrollToTop } from '../../../../sources/common/triggerEvent';
import { onToggleBuildUp } from '../../../../sources/list/onToggleBuildUp';
import NormalCalendarLowPrice from '../../../../components/list/top/calendarLowprice/normal.js';
import { getTheMinPriceOfAllVisibleFlightsSelector } from '../../sidebar/filterbar/filterbarSelector';
import { calcSplitRoundSelector } from '../../../../sources/list/calcSplitRoundStateSelector';

class NormalCalendarContainer extends React.Component {
	constructor(props) {
		super(props);
	}

	onSetCurrentStart = () => {
		let firstDepartDate = this.props.searchCriteria.getIn(['flightSegments', 0, 'departureDate']);

		const oldStartDays = this.props.currentStart;
		const departDateLaterOrEqualThanLeftDate =
			moment()
				.add(oldStartDays - 1, 'days')
				.diff(moment(firstDepartDate)) <= 0;
		const departDateEarlierOrEqualThanRightDate =
			moment()
				.add(oldStartDays + 6, 'days')
				.diff(moment(firstDepartDate)) >= 0;
		const targetDepartDateBetweenOldRange =
			departDateLaterOrEqualThanLeftDate && departDateEarlierOrEqualThanRightDate;
		if (!targetDepartDateBetweenOldRange) {
			let searchCriteria = this.props.searchCriteria, //搜索条件
				//   出发日期和今天的比较

				diffDaysWithSearch = Math.ceil(
					moment(firstDepartDate).diff(moment(moment().format('YYYY-MM-DD')), 'days')
				),
				firstAndLastDepartDateDiffDays = 0;
			if (searchCriteria.get('flightWay') === 'D') {
				firstAndLastDepartDateDiffDays = moment(
					searchCriteria.getIn(['flightSegments', 1, 'departureDate'])
				).diff(searchCriteria.getIn(['flightSegments', 0, 'departureDate']), 'days');
			}

			//初始化展开前试图视图位置
			let totalDays =
					moment(moment().format('YYYY-MM-DD'))
						.add(1, 'years')
						.diff(moment(moment().format('YYYY-MM-DD')), 'days') - firstAndLastDepartDateDiffDays,
				newStartDays =
					diffDaysWithSearch > 3
						? totalDays - diffDaysWithSearch <= 3
							? totalDays - 6
							: diffDaysWithSearch - 3
						: 0;
			this.props.changeCurrentStart(newStartDays);
		}
	};

	componentDidMount() {
		this.onSetCurrentStart();
	}
	componentWillReceiveProps() {
		let url = window.location.search;
		if (url.includes('nearByDate')) {
			this.switchCalendarShow(true);
		}

		this.setState({ countClick: true });
	}
	componentDidUpdate(prevProps) {
		if (prevProps.routerKey !== this.props.routerKey) {
			const prevSearchCriteria = prevProps.searchCriteria,
				currentSearchCriteria = this.props.searchCriteria;

			if (
				prevSearchCriteria &&
				currentSearchCriteria &&
				prevSearchCriteria.getIn(['flightSegments', 0, 'departureDate']) !==
					currentSearchCriteria.getIn(['flightSegments', 0, 'departureDate'])
			) {
				this.onSetCurrentStart();
			}
		}
	}

	switchCalendarShow = (isShow) => {
		if (this.props.fixLowPriceCalendar && isShow) {
			windowScrollToTop(0);
		}

		this.props.switchCalendarShow(isShow);
	};

	doToggleBuildUp = (isBuildUp) => {
		this.props.toggleBuildUp(isBuildUp);
	};

	render() {
		let { containsTax, lowPriceOfPage, lowPriceChannelToday, lowPriceTodayByInterface, searchFlightsIsFinish } =
				this.props,
			lowest_list_price = searchFlightsIsFinish
				? containsTax
					? lowPriceOfPage.get('totalPrice')
					: lowPriceOfPage.get('price')
				: 0,
			is_tax = containsTax ? 1 : 0,
			calendar_price = containsTax
				? lowPriceTodayByInterface.get('totalPrice')
				: lowPriceTodayByInterface.get('price'),
			lowest_list_engine = containsTax
				? lowPriceChannelToday.get('lowestTotalPriceChannel')
				: lowPriceChannelToday.get('lowestPriceChannel');

		return (
			<UbtBoundary
				tracker={ListUbtGenerator(
					LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW,
					LIST_UBT_KEY_TYPES.LOWEST_PRICE_COMPARE,
					lowest_list_price,
					is_tax,
					calendar_price,
					lowest_list_engine
				)}
			>
				<NormalCalendarLowPrice
					{...this.props}
					toggleBuildUp={this.doToggleBuildUp}
					switchShow={this.switchCalendarShow}
				/>
			</UbtBoundary>
		);
	}
}

const mapStateToProps = (state, props) => {
	const { splitRound } = props;

	let theMinPriceOfAllVisibleFlights = calcSplitRoundSelector(
		splitRound,
		getTheMinPriceOfAllVisibleFlightsSelector,
		state,
		true
	);
	// 最低价日期合集
	let getNormalLowPriceSelector = calcSplitRoundSelector(splitRound, getNormalLowPrice, state, true);
	//搜索框下面横向的低价数据数据源
	let getCalendarLPDataSelector = calcSplitRoundSelector(splitRound, getCalendarLPData, state, true);
	return {
		calendarLPData: getCalendarLPDataSelector,
		searchCriteria: state.getIn(['list', 'search', 'prevCond']),
		currentStart: state.getIn(['list', 'calendar_lowprice', 'currentStart']),
		lowerPriceSwitchStatus: state.getIn(['list', 'calendar_lowprice', 'lowerPriceSwitchStatus']),
		containsTax: state.getIn(['list', 'sort', 'containsTax']),
		normalLowPrice: getNormalLowPriceSelector,
		toRememberActiveKeys: getToRememberActiveKeysSelector(state),
		lowPriceOfPage: theMinPriceOfAllVisibleFlights,
		lowPriceChannelToday: state.getIn(['list', 'calendar_lowprice', 'lowPriceChannelToday']),
		lowPriceTodayByInterface: state.getIn(['list', 'calendar_lowprice', 'lowPriceTodayByInterface']),
		searchFlightsIsFinish: searchFlightsIsFinishedSelector(state),
		routerKey: getRouterKeySelector(state),
		// dayCount: getLowPriceCalendarDisplayDayCount(state),
		filterV2: true,
		fixLowPriceCalendar: getFixLowPriceCalendarSelector(state),
		rawSplitRoundFlightsSwitch: getRawSplitRoundFlightsSwitchSelector(state),
		splitRoundFlightsSwitch: false,
		isBuildUp: isBuildUpSelector(state),
		globalSwitch: globalSwitchSelector(state),
		flightWay: prevCondFlightWaySelector(state),
		roundTabModeSwitch: getRoundTabModeSwitchSelector(state),
		fixSortBar: getFixSortBarSelector(state),
	};
};

const mapDispatchToProps = (dispatch) => ({
	switchCalendarShow: (isShow) => {
		dispatch(genSwitchCalendarShow(isShow));
	},
	changeCurrentStart: (currentStart) => {
		dispatch(genChangeCurrentStart(currentStart));
	},
	changeCurrentMonth: (currentMonthIndex) => {
		dispatch(genChangeCurrentMonth(currentMonthIndex));
	},
	fetchLowerPriceAllSwitch: () => {
		dispatch(fetchLowerPriceAllSwitch());
	},
	toggleBuildUp: (isBuildUp) => {
		onToggleBuildUp(isBuildUp);
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(NormalCalendarContainer);
